.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  -webkit-overflow-scrolling: touch;
}

.ReactModal__Overlay--after-open{
  background-color: rgba(0, 0, 0, 0.40);
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Body--open{
  overflow: hidden;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.vacancy-modal-close-btn {
  display: inline-block;
  background-color: transparent;
  border: 0;
  width: 52px;
  height: 52px;
  padding: 15px;
  position: absolute;
  right: 35px;
  top: 48px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyMnB4IiBoZWlnaHQ9IjIycHgiIHZpZXdCb3g9IjAgMCAyMiAyMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT7Ql9Cw0LrRgNGL0YLRjDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSLQl9Cw0LrRgNGL0YLRjCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEuMDAwMDAwLCAtMS4wMDAwMDApIiBmaWxsPSIjQTNCOEMxIj4gICAgICAgICAgICA8cGF0aCBkPSJNMTEuOTY1LDEwLjU3NSBMMjEuMjcsMS4yNyBDMjEuNTI1NDQ0OSwxLjAxNDU1NDk0IDIxLjg5Nzc2NCwwLjkxNDc5MjIzIDIyLjI0NjcwODMsMS4wMDgyOTE1MyBDMjIuNTk1NjUyNiwxLjEwMTc5MDgzIDIyLjg2ODIwOTIsMS4zNzQzNDczNyAyMi45NjE3MDg1LDEuNzIzMjkxNjkgQzIzLjA1NTIwNzgsMi4wNzIyMzYwMSAyMi45NTU0NDUxLDIuNDQ0NTU1MTQgMjIuNywyLjcgTDEzLjM5NSwxMi4wMDUgTDIyLjU4LDIxLjE5IEMyMi44MzM2NTg3LDIxLjQ0MzY1ODUgMjIuOTMyNzIzOCwyMS44MTMzNzQgMjIuODM5ODc4MywyMi4xNTk4NzgyIEMyMi43NDcwMzI5LDIyLjUwNjM4MjMgMjIuNDc2MzgyMywyMi43NzcwMzI5IDIyLjEyOTg3ODIsMjIuODY5ODc4MyBDMjEuNzgzMzc0LDIyLjk2MjcyMzggMjEuNDEzNjU4NSwyMi44NjM2NTg3IDIxLjE2LDIyLjYxIEwxMS45NzUsMTMuNDI1IEwyLjcsMjIuNyBDMi4zMDUxMTYzNiwyMy4wOTQ4ODMzIDEuNjY0ODgzNzYsMjMuMDk0ODgzMiAxLjI3MDAwMDI3LDIyLjY5OTk5OTcgQzAuODc1MTE2NzgyLDIyLjMwNTExNjIgMC44NzUxMTY2NiwyMS42NjQ4ODM2IDEuMjcsMjEuMjcgTDEwLjU0NSwxMS45OTUgTDEuMzYsMi44MSBDMS4xMDYzNDE0MywyLjU1NjM0MTQzIDEuMDA3Mjc2NDgsMi4xODY2MjYwMSAxLjEwMDEyMTk2LDEuODQwMTIxOTYgQzEuMTkyOTY3NDQsMS40OTM2MTc5MSAxLjQ2MzYxNzkxLDEuMjIyOTY3NDQgMS44MTAxMjE5NiwxLjEzMDEyMTk2IEMyLjE1NjYyNjAxLDEuMDM3Mjc2NDggMi41MjYzNDE0MywxLjEzNjM0MTQzIDIuNzgsMS4zOSBMMTEuOTY1LDEwLjU3NSBaIj48L3BhdGg+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=');
  &:hover{
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyMnB4IiBoZWlnaHQ9IjIycHgiIHZpZXdCb3g9IjAgMCAyMiAyMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5jbG9zZTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJjbG9zZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEuMDAwMDAwLCAtMS4wMDAwMDApIiBmaWxsPSIjRkY0MTM2IiBmaWxsLXJ1bGU9Im5vbnplcm8iPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMS45NjUsMTAuNTc1IEwyMS4yNywxLjI3IEMyMS41MjU0NDQ5LDEuMDE0NTU0OTQgMjEuODk3NzY0LDAuOTE0NzkyMjMgMjIuMjQ2NzA4MywxLjAwODI5MTUzIEMyMi41OTU2NTI2LDEuMTAxNzkwODMgMjIuODY4MjA5MiwxLjM3NDM0NzM3IDIyLjk2MTcwODUsMS43MjMyOTE2OSBDMjMuMDU1MjA3OCwyLjA3MjIzNjAxIDIyLjk1NTQ0NTEsMi40NDQ1NTUxNCAyMi43LDIuNyBMMTMuMzk1LDEyLjAwNSBMMjIuNTgsMjEuMTkgQzIyLjgzMzY1ODcsMjEuNDQzNjU4NSAyMi45MzI3MjM4LDIxLjgxMzM3NCAyMi44Mzk4NzgzLDIyLjE1OTg3ODIgQzIyLjc0NzAzMjksMjIuNTA2MzgyMyAyMi40NzYzODIzLDIyLjc3NzAzMjkgMjIuMTI5ODc4MiwyMi44Njk4NzgzIEMyMS43ODMzNzQsMjIuOTYyNzIzOCAyMS40MTM2NTg1LDIyLjg2MzY1ODcgMjEuMTYsMjIuNjEgTDExLjk3NSwxMy40MjUgTDIuNywyMi43IEMyLjMwNTExNjM2LDIzLjA5NDg4MzMgMS42NjQ4ODM3NiwyMy4wOTQ4ODMyIDEuMjcwMDAwMjcsMjIuNjk5OTk5NyBDMC44NzUxMTY3ODIsMjIuMzA1MTE2MiAwLjg3NTExNjY2LDIxLjY2NDg4MzYgMS4yNywyMS4yNyBMMTAuNTQ1LDExLjk5NSBMMS4zNiwyLjgxIEMxLjEwNjM0MTQzLDIuNTU2MzQxNDMgMS4wMDcyNzY0OCwyLjE4NjYyNjAxIDEuMTAwMTIxOTYsMS44NDAxMjE5NiBDMS4xOTI5Njc0NCwxLjQ5MzYxNzkxIDEuNDYzNjE3OTEsMS4yMjI5Njc0NCAxLjgxMDEyMTk2LDEuMTMwMTIxOTYgQzIuMTU2NjI2MDEsMS4wMzcyNzY0OCAyLjUyNjM0MTQzLDEuMTM2MzQxNDMgMi43OCwxLjM5IEwxMS45NjUsMTAuNTc1IFoiIGlkPSJQYXRoIj48L3BhdGg+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=');
  }
}

.vacancy-modal{
  max-height: 100%;
  overflow: auto;
  padding: 89px 125px 10px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  margin-top: 20px;
  &:after{
    content: '';
    display: table;
    clear: both;
    height: 80px;
  }
  .vacancy-schedule{
    margin-top: 5px;
  }
  .vacancy-experience{
    margin-top: 30px;
  }
  .vacancy-responsibility{
    margin-top: 30px;
  }
  .responsibility-title{
    list-style: disc;
    padding-left: 20px;
    margin: 30px 0 80px;
  }
  .responsibility-item {
    margin-bottom: 30px;
    ul{
      list-style: circle;
      padding: 10px 0 0 20px;
      li{
        line-height: 1.4;
      }
    }
  }
  .vacancy-description {
    b {
      margin-top: 30px;
      display: inline-block;
      margin-right: 10px;
    }

    & > ul {
      list-style: disc;
      padding-left: 20px;
      margin: 30px 0 80px;

      li {
        margin-bottom: 30px;

        & > ul {
          list-style: circle;
          padding: 15px 0 0 20px;

          li {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .vacancy-salary{
    color: #ff4136;
    margin-top: 30px;
  }
  .vacancy-experience,.vacancy-salary{
    b{
      margin-right: 10px;
    }
    & > div{
      display: inline-block;
    }
  }
  .form-group input, .form-group textarea{
    max-width: 100%;
  }
  .form-group input, .form-group textarea,.form-check label:before{
    background-color: #fff;
  }
  .form-check {
      position: relative;
  }
  .btn{
    width: 100%;
  }
}
.submit-modal{
  padding: 100px;
  text-align: center;
  p{
    font-size: 18px;
    line-height: 1.33;
    padding: 40px 0;
  }
  .btn{
    min-width: 220px;
  }
  .icon-status{
    margin-bottom: 25px;
  }
}

